var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog, DialogContent, DialogContentText } from '@mui/material';
export default function AlertDialog(props) {
    var open = props.open, setOpen = props.setOpen, send = props.send, subtitle = props.subtitle;
    return (_jsx(_Fragment, { children: _jsx(Dialog, __assign({ open: open, onClose: function () { return setOpen(false); }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: _jsxs(DialogContent, __assign({ className: "forced_box_editor" }, { children: [_jsxs("div", __assign({ className: "dialog_header" }, { children: [_jsx("h2", __assign({ className: 'dialog_h2' }, { children: "Confirm" })), _jsx(DialogContentText, { children: subtitle ? subtitle : 'Are you sure you want to proceed?' })] })), _jsxs("div", __assign({ className: "buttons" }, { children: [_jsx(Button, __assign({ variant: "outlined", color: "primary", onClick: function () { return setOpen(false); } }, { children: "Cancel" })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                                    setOpen(false);
                                    send();
                                } }, { children: "Confirm" }))] }))] })) })) }));
}
;
