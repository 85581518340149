export var ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? {
    api_url: "http://localhost:3000",
    //api_url: "http://shofi.localhost.com/api/",
    //api_url: "https://api.goshofi.com/api/",
    STRIPE_PUBLIC_KEY: "pk_test_51NpZ4yJERu02kDeGMSwl6JVF5BpSByIHzzj3ON1Csk9ekckPViPeR9oLliIp94NjtRjthfzAy00ZAEMCvJeDFHC500aX22iQun",
    GMAP_API_KEY: "AIzaSyDXxN3BGzWBMDCH_k1bKKW2BtUjBeflAFw",
}
    :
        {
            api_url: "https://api.pennicart.io",
            STRIPE_PUBLIC_KEY: "pk_test_51NpZ4yJERu02kDeGMSwl6JVF5BpSByIHzzj3ON1Csk9ekckPViPeR9oLliIp94NjtRjthfzAy00ZAEMCvJeDFHC500aX22iQun", GMAP_API_KEY: "AIzaSyDXxN3BGzWBMDCH_k1bKKW2BtUjBeflAFw",
        };
