var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import ic_arrow_right from '../icons/ic_arrow_right.svg';
import ic_arrow_left from '../icons/ic_arrow_left.svg';
export default function ListPagination(props) {
    var _a = props.pagination, pagination = _a === void 0 ? {
        total_pages: 1,
        total_items: 0,
    } : _a, _b = props.page, page = _b === void 0 ? 1 : _b, _c = props.onChange, onChange = _c === void 0 ? function () { } : _c, _d = props.label, label = _d === void 0 ? "Items" : _d;
    var handlePrevious = function () {
        if (page > 1) {
            onChange(null, page - 1);
        }
    };
    var handleNext = function () {
        if (page < pagination.total_pages) {
            onChange(null, page + 1);
        }
    };
    return (_jsxs("div", __assign({ className: "ListPagination" }, { children: [_jsxs("span", __assign({ className: "PaginationLabel" }, { children: [pagination.total_items, " ", label] })), _jsxs("span", __assign({ style: { marginRight: '8px' } }, { children: ["Page ", page, " of ", pagination.total_pages] })), _jsx("button", __assign({ onClick: handlePrevious, disabled: page === 1, style: { background: 'none', border: 'none', cursor: 'pointer', opacity: page === 1 ? 0.5 : 1 } }, { children: _jsx("img", { src: ic_arrow_left, alt: "Previous" }) })), _jsx("button", __assign({ onClick: handleNext, disabled: page === pagination.total_pages, style: { background: 'none', border: 'none', cursor: 'pointer', opacity: page === pagination.total_pages ? 0.5 : 1 } }, { children: _jsx("img", { src: ic_arrow_right, alt: "Next" }) }))] })));
}
