var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import ISwitch from "elements/ISwitch";
var defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: {
        is_active: true,
    },
};
export default function BulkDiscount(props) {
    var _a;
    var setLogicType = props.setLogicType, logicValues = props.logicValues, setLogicvalues = props.setLogicvalues;
    useEffect(function () {
        console.log("logicValues----", logicValues);
        if (!logicValues.logic_id) {
            console.log("newww");
            setLogicvalues(defaultValues);
        }
    }, []);
    useEffect(function () {
        if (!logicValues.conditions) {
            setLogicvalues(__assign(__assign({}, logicValues), { conditions: {
                    is_active: true,
                } }));
        }
    }, [logicValues]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "boxContainerHeader" }, { children: _jsx("div", __assign({ className: "boxContainerTitle" }, { children: _jsx("h2", __assign({ className: "h2_title" }, { children: "Bulk & Tiered Discounts" })) })) })), _jsx(TextField, { fullWidth: true, label: "Name", value: logicValues.logic_name, onChange: function (e) { return setLogicvalues(__assign(__assign({}, logicValues), { logic_name: e.target.value })); } }), _jsx(ISwitch, { label: "Turn on Tiered Pricing", onChange: function (e) {
                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: __assign(__assign({}, logicValues.conditions), { is_active: e.target.checked }) }));
                }, checked: ((_a = logicValues.conditions) === null || _a === void 0 ? void 0 : _a.is_active) || false, name: "is_active" })] }));
}
