var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
var defaultConditions = function () { return ({
    rules: [
        {
            rule: "",
            condition: "",
            value: "",
        }
    ],
}); };
var defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: [defaultConditions()],
};
export default function PurchaseOrder(props) {
    var setLogicType = props.setLogicType, logicValues = props.logicValues, setLogicvalues = props.setLogicvalues;
    useEffect(function () {
        if (!logicValues.logic_id) {
            setLogicvalues(defaultValues);
        }
    }, [logicValues.logic_id, setLogicvalues]);
    useEffect(function () {
        if (!Array.isArray(logicValues.conditions)) {
            setLogicvalues(__assign(__assign({}, logicValues), { conditions: [defaultConditions()] }));
        }
    }, [logicValues, setLogicvalues]);
    if (!Array.isArray(logicValues.conditions)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "boxContainerHeader" }, { children: _jsx("div", __assign({ className: "boxContainerTitle" }, { children: _jsx("h2", __assign({ className: "h2_title" }, { children: "Purchase Order" })) })) })), _jsx(TextField, { fullWidth: true, label: "Name", value: logicValues.logic_name, onChange: function (e) { return setLogicvalues(__assign(__assign({}, logicValues), { logic_name: e.target.value })); } }), _jsx("div", __assign({ className: "input_group" }, { children: logicValues.conditions.map(function (condition, index) { return (_jsxs("div", __assign({ className: "input_group" }, { children: [_jsxs("div", __assign({ className: "dialog_section_title" }, { children: [_jsxs("div", { children: [_jsx("h2", __assign({ className: "h2_section_title" }, { children: "Conditions" })), _jsx("span", __assign({ className: "subitle" }, { children: "Add special conditions here by clicking 'Add Rule'" }))] }), _jsx(IconButton, __assign({ "aria-label": "delete", disabled: logicValues.conditions.length === 1, onClick: function () {
                                        var conditions = __spreadArray([], logicValues.conditions, true);
                                        conditions.splice(index, 1);
                                        setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                    } }, { children: _jsx(DeleteIcon, {}) }))] })), condition.rules.map(function (rule, ruleIndex) { return (_jsx("div", __assign({ className: "input_group" }, { children: _jsxs("div", __assign({ className: "input_row" }, { children: [_jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Rule" })), _jsxs(Select, __assign({ labelId: "demo-simple-select-label", id: "rule", value: rule.rule, label: "Rule", name: "rule", onChange: function (e) {
                                                    var conditions = __spreadArray([], logicValues.conditions, true);
                                                    conditions[index].rules[ruleIndex].rule = e.target.value;
                                                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                } }, { children: [_jsx(MenuItem, __assign({ value: "cart_total" }, { children: "Cart Total" })), _jsx(MenuItem, __assign({ value: "weight" }, { children: "Weight" })), _jsx(MenuItem, __assign({ value: "category" }, { children: "Category" })), _jsx(MenuItem, __assign({ value: "custom" }, { children: "Custom" }))] }))] })), _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Condition" })), _jsxs(Select, __assign({ labelId: "demo-simple-select-label", id: "condition", value: rule.condition, label: "Condition", name: "rule_condition", onChange: function (e) {
                                                    var conditions = __spreadArray([], logicValues.conditions, true);
                                                    conditions[index].rules[ruleIndex].condition = e.target.value;
                                                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                } }, { children: [_jsx(MenuItem, __assign({ value: "=" }, { children: "Equals" })), _jsx(MenuItem, __assign({ value: "!=" }, { children: "Does Not Equal" })), _jsx(MenuItem, __assign({ value: "==" }, { children: "Exact Match" })), _jsx(MenuItem, __assign({ value: ">" }, { children: "Greater Than" })), _jsx(MenuItem, __assign({ value: ">=" }, { children: "Greater Than or Equal To" })), _jsx(MenuItem, __assign({ value: "<" }, { children: "Less Than" })), _jsx(MenuItem, __assign({ value: "<=" }, { children: "Less Than or Equal To" })), _jsx(MenuItem, __assign({ value: "boolean" }, { children: "Boolean" }))] }))] })), _jsx(TextField, { fullWidth: true, label: "Value", value: rule.value, onChange: function (e) {
                                            var conditions = __spreadArray([], logicValues.conditions, true);
                                            conditions[index].rules[ruleIndex].value = e.target.value;
                                            setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                        } }), _jsx(IconButton, __assign({ "aria-label": "delete", disabled: condition.rules.length === 1, onClick: function () {
                                            var conditions = __spreadArray([], logicValues.conditions, true);
                                            conditions[index].rules.splice(ruleIndex, 1);
                                            setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                        } }, { children: _jsx(DeleteIcon, {}) }))] })) }), ruleIndex)); }), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                                var conditions = __spreadArray([], logicValues.conditions, true);
                                conditions[index].rules.push({
                                    rule: "",
                                    condition: "",
                                    value: "",
                                });
                                setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                            } }, { children: "Add Rule" }))] }), index)); }) }))] }));
}
