var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export default function LogicMenu(props) {
    var setLogicType = props.setLogicType;
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ variant: "text", color: "primary", className: "logic_button", fullWidth: true, onClick: function () {
                    setLogicType({
                        logic_type: "shipping",
                        logic_type_id: 1
                    });
                }, endIcon: _jsx(ArrowForwardIosIcon, { sx: { width: "14px" } }) }, { children: _jsxs("div", __assign({ className: "logic_button_text" }, { children: [_jsx("span", __assign({ className: "login_button_title" }, { children: "Shipping logic" })), _jsx("span", __assign({ className: "login_button_description" }, { children: "Set custom shipping rates and conditions" }))] })) })), _jsx(Button, __assign({ variant: "text", color: "primary", className: "logic_button", fullWidth: true, onClick: function () {
                    setLogicType({
                        logic_type: "bulk_discount",
                        logic_type_id: 3
                    });
                }, endIcon: _jsx(ArrowForwardIosIcon, { sx: { width: "14px" } }) }, { children: _jsxs("div", __assign({ className: "logic_button_text" }, { children: [_jsx("span", __assign({ className: "login_button_title" }, { children: "Bulk & Tiered Discounts" })), _jsx("span", __assign({ className: "login_button_description" }, { children: "Implement volume-based discount strategies" }))] })) })), _jsx(Button, __assign({ variant: "text", color: "primary", className: "logic_button", fullWidth: true, onClick: function () {
                    setLogicType({
                        logic_type: "hide_variables",
                        logic_type_id: 4
                    });
                }, endIcon: _jsx(ArrowForwardIosIcon, { sx: { width: "14px" } }) }, { children: _jsxs("div", __assign({ className: "logic_button_text" }, { children: [_jsx("span", __assign({ className: "login_button_title" }, { children: "Hide Variables" })), _jsx("span", __assign({ className: "login_button_description" }, { children: "Manage visible elements in the cart with ease" }))] })) })), _jsx(Button, __assign({ variant: "text", color: "primary", className: "logic_button", fullWidth: true, onClick: function () {
                    setLogicType({
                        logic_type: "build_bundle",
                        logic_type_id: 5
                    });
                }, endIcon: _jsx(ArrowForwardIosIcon, { sx: { width: "14px" } }) }, { children: _jsxs("div", __assign({ className: "logic_button_text" }, { children: [_jsx("span", __assign({ className: "login_button_title" }, { children: "Build A Bundle" })), _jsx("span", __assign({ className: "login_button_description" }, { children: "Offer special pricing on bundled items" }))] })) }))] }));
}
