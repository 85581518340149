var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiRequest } from "GlobalFunctions";
import ISwitch from "elements/ISwitch";
import CloseIcon from '@mui/icons-material/Close';
var defaultConditions = function () { return ({
    rules: [
        {
            rule: "",
            custom_value: "",
            condition: "",
            value: "",
        }
    ],
    addToAll: true,
    shippingMethods: [],
    price: "",
}); };
var defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: [defaultConditions()],
};
export default function ShippingLogic(props) {
    var _this = this;
    var _a = useState([]), shippingMethods = _a[0], setShippingMethods = _a[1];
    var getShippingMethods = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiRequest({
                        url: "/v2/shipping/list",
                        method: "get",
                        headers: {
                            "site_id": localStorage.getItem('site-id-selected'),
                        },
                        query: {
                            full_list: true,
                        },
                        setResponse: function (response) {
                            setShippingMethods(response.list);
                        },
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getShippingMethods();
    }, []);
    var setLogicType = props.setLogicType, logicValues = props.logicValues, setLogicvalues = props.setLogicvalues;
    useEffect(function () {
        console.log("logicValues----", logicValues);
        if (!logicValues.logic_id) {
            console.log("newww");
            setLogicvalues(defaultValues);
        }
    }, []);
    useEffect(function () {
        if (!logicValues.conditions) {
            setLogicvalues(__assign(__assign({}, logicValues), { conditions: [defaultConditions()] }));
        }
    }, [logicValues]);
    return (_jsxs(_Fragment, { children: [_jsx(InputLabel, __assign({ className: "external_label" }, { children: "Name" })), _jsx(TextField, { fullWidth: true, label: "Enter Name To Identify", size: "small", value: logicValues.logic_name, onChange: function (e) { return setLogicvalues(__assign(__assign({}, logicValues), { logic_name: e.target.value })); } }), _jsxs("div", __assign({ className: "input_group" }, { children: [Array.isArray(logicValues.conditions) && logicValues.conditions.map(function (condition, index) { return (_jsxs("div", __assign({ className: "input_group" }, { children: [_jsxs("div", __assign({ className: "dialog_section_title" }, { children: [_jsxs("div", { children: [_jsx("h2", __assign({ className: "h2_section_title" }, { children: "Conditions" })), _jsx("span", __assign({ className: "section_subtitle" }, { children: "Add special conditions here by clicking 'Add Rule'" }))] }), _jsx(IconButton, __assign({ "aria-label": "delete", disabled: logicValues.conditions.length === 1, onClick: function () {
                                            var conditions = __spreadArray([], logicValues.conditions, true);
                                            conditions.splice(index, 1);
                                            setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                        } }, { children: _jsx(DeleteIcon, {}) }))] })), condition.rules.map(function (rule, ruleIndex) {
                                var type_value = "text";
                                if (rule.rule === "cart_total" || rule.rule === "weight") {
                                    type_value = "number";
                                }
                                if (rule.condition === ">=" || rule.condition === "<=" || rule.condition === ">" || rule.condition === "<") {
                                    type_value = "number";
                                }
                                return (_jsx("div", __assign({ className: "input_group" }, { children: _jsxs("div", __assign({ className: "input_row" }, { children: [rule.rule !== "custom" ? (_jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Rule" })), _jsxs(Select, __assign({ labelId: "demo-simple-select-label", id: "rule", value: rule.rule, label: "Rule", name: "rule", onChange: function (e) {
                                                            var conditions = __spreadArray([], logicValues.conditions, true);
                                                            conditions[index].rules[ruleIndex].rule = e.target.value;
                                                            conditions[index].rules[ruleIndex].custom_value = "";
                                                            conditions[index].rules[ruleIndex].value = "";
                                                            setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                        } }, { children: [_jsx(MenuItem, __assign({ value: "cart_total" }, { children: "Cart Total" })), _jsx(MenuItem, __assign({ value: "weight" }, { children: "Weight" })), _jsx(MenuItem, __assign({ value: "category" }, { children: "Category" })), _jsx(MenuItem, __assign({ value: "custom" }, { children: "Custom" }))] }))] }))) : (_jsx(TextField, { fullWidth: true, label: "Custom", value: rule.custom_value, onChange: function (e) {
                                                    var conditions = __spreadArray([], logicValues.conditions, true);
                                                    conditions[index].rules[ruleIndex].custom_value = e.target.value;
                                                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                }, InputProps: {
                                                    endAdornment: (_jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ onClick: function () {
                                                                var conditions = __spreadArray([], logicValues.conditions, true);
                                                                conditions[index].rules[ruleIndex].rule = ""; // Cambia aquí para volver a mostrar el select
                                                                setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                            } }, { children: _jsx(CloseIcon, { fontSize: "small" }) })) }))),
                                                } })), _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Condition" })), _jsxs(Select, __assign({ labelId: "demo-simple-select-label", id: "condition", value: rule.condition, label: "Condition", name: "rule_condition", onChange: function (e) {
                                                            var conditions = __spreadArray([], logicValues.conditions, true);
                                                            conditions[index].rules[ruleIndex].condition = e.target.value;
                                                            setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                        } }, { children: [_jsx(MenuItem, __assign({ value: "=" }, { children: "Equals" })), _jsx(MenuItem, __assign({ value: "!=" }, { children: "Does Not Equal" })), _jsx(MenuItem, __assign({ value: "==" }, { children: "Exact Match" })), rule.rule !== "category" &&
                                                                (_jsx(MenuItem, __assign({ value: ">" }, { children: "Greater Than" }))), rule.rule !== "category" && (_jsx(MenuItem, __assign({ value: ">=" }, { children: "Greater Than or Equal To" }))), rule.rule !== "category" && (_jsx(MenuItem, __assign({ value: "<" }, { children: "Less Than" }))), rule.rule !== "category" && (_jsx(MenuItem, __assign({ value: "<=" }, { children: "Less Than or Equal To" }))), _jsx(MenuItem, __assign({ value: "boolean" }, { children: "Boolean" }))] }))] })), _jsx(TextField, { fullWidth: true, label: "Value", value: rule.value, onChange: function (e) {
                                                    var conditions = __spreadArray([], logicValues.conditions, true);
                                                    conditions[index].rules[ruleIndex].value = e.target.value;
                                                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                }, type: type_value }), _jsx(IconButton, __assign({ "aria-label": "delete", disabled: condition.rules.length === 1, onClick: function () {
                                                    var conditions = __spreadArray([], logicValues.conditions, true);
                                                    conditions[index].rules.splice(ruleIndex, 1);
                                                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                } }, { children: _jsx(DeleteIcon, {}) }))] })) }), ruleIndex));
                            }), _jsx("div", __assign({ className: "form_button_wrapper" }, { children: _jsx(Button, __assign({ variant: "outlined", color: "info", className: "btnList", onClick: function () {
                                        var conditions = __spreadArray([], logicValues.conditions, true);
                                        conditions[index].rules.push({
                                            rule: "",
                                            condition: "",
                                            value: "",
                                        });
                                        setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                    } }, { children: "Add Rule" })) })), _jsxs(RadioGroup, __assign({ row: true, "aria-label": "addToAll", name: "addToAll", color: "primary", className: "radio_group", value: condition.addToAll, onChange: function (e) {
                                    var conditions = __spreadArray([], logicValues.conditions, true);
                                    conditions[index].addToAll = e.target.value === "true" ? true : false;
                                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                }, style: { display: "flex", flexDirection: "column" } }, { children: [_jsx(FormControlLabel, { style: { color: "#424242" }, value: "true", control: _jsx(Radio, {}), label: "Apply to all shipping classes" }), _jsx(FormControlLabel, { style: { color: "#424242" }, value: "false", control: _jsx(Radio, {}), label: "Apply to specific shipping classes" })] })), !logicValues.conditions[index].addToAll ? (_jsx("div", __assign({ className: "logic_shipping_methods" }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Shipping Method" }), _jsx(TableCell, {})] }) }), _jsx(TableBody, { children: shippingMethods.map(function (method, methodIndex) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(ISwitch, { label: method.shipping_name, className: "input_switch", onChange: function (e) {
                                                                var conditions = __spreadArray([], logicValues.conditions, true);
                                                                if (e.target.checked) {
                                                                    conditions[index].shippingMethods.push({ shipping_id: method.shipping_id, price: "" });
                                                                }
                                                                else {
                                                                    conditions[index].shippingMethods = conditions[index].shippingMethods.filter(function (item) { return item.shipping_id !== method.shipping_id; });
                                                                }
                                                                setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                            }, checked: condition.shippingMethods.some(function (item) { return item.shipping_id === method.shipping_id; }), name: method.shipping_name }) }), _jsx(TableCell, { children: _jsxs("div", __assign({ className: "logic_shipping_price_container" }, { children: [_jsx("span", __assign({ className: "logic_shipping_price" }, { children: "PRICE" })), condition.shippingMethods.some(function (item) { return item.shipping_id === method.shipping_id; }) ? (_jsx(TextField, { fullWidth: true, label: "Price", size: "small", value: condition.shippingMethods.find(function (item) { return item.shipping_id === method.shipping_id; }).price, onChange: function (e) {
                                                                        var conditions = __spreadArray([], logicValues.conditions, true);
                                                                        conditions[index].shippingMethods.find(function (item) { return item.shipping_id === method.shipping_id; }).price = e.target.value;
                                                                        setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                                                    } })) : (_jsx(TextField, { disabled: true, sx: { opacity: 0.5 }, fullWidth: true, label: "Price", size: "small", value: "" }))] })) })] }, methodIndex)); }) })] }) }))) : (_jsx(TextField, { fullWidth: true, label: "Price", variant: "outlined", value: condition.price, style: { maxWidth: "200px" }, onChange: function (e) {
                                    var conditions = __spreadArray([], logicValues.conditions, true);
                                    conditions[index].price = e.target.value;
                                    setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                } }))] }), index)); }), _jsx("div", __assign({ className: "form_button_wrapper" }, { children: _jsx(Button, __assign({ variant: "outlined", color: "info", className: "btnList", onClick: function () {
                                var conditions = __spreadArray([], logicValues.conditions, true);
                                conditions.push(defaultConditions());
                                setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                            } }, { children: "Add Condition" })) }))] }))] }));
}
