var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ProjectConfig } from 'Global';
import { useSnackbar } from "notistack";
import LogicMenu from "./components/LogicMenu";
import ShippingLogic from "./components/ShippingLogic";
import PurchaseOrder from "./components/PurchaseOrder";
import BulkDiscount from "./components/BulkDiscount";
import HideVariables from "./components/HideVariables";
import BuildBundle from "./components/BuildBundle";
import Custom from "./components/Custom";
import ic_logic from 'icons/ic_logic.svg';
export default function LogicEditor(props) {
    /*=============================================
    VARIABLES FROM PROPS
    ============================================= */
    var setLoading = props.setLoading, openEditor = props.openEditor, setOpenEditor = props.setOpenEditor, logic_id = props.logic_id, loadList = props.loadList;
    console.log("logic_id", logic_id);
    var _a = useState({}), logicType = _a[0], setLogicType = _a[1];
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    var _b = useSnackbar(), enqueueSnackbar = _b.enqueueSnackbar, closeSnackbar = _b.closeSnackbar;
    /*============================================= */
    var _c = useState({}), logicValues = _c[0], setLogicValues = _c[1];
    /*=======================================
    GET USER DETAILS IF USER_ID EXISTS
    =======================================*/
    useEffect(function () {
        if (logic_id) {
            axios.get(ProjectConfig.api_url + "/v2/logic/details", {
                params: {
                    logic_id: logic_id,
                },
            })
                .then(function (response) {
                console.log("DETAILS res", response);
                setLogicValues(response.data.data);
                setLogicType(response.data.logic_type);
            })
                .catch(function (err) {
                console.log(err.response.data);
            });
        }
        else {
            setLogicValues({});
            setLogicType({});
        }
    }, [openEditor]);
    /*=======================================
    SUBMIT FUNCTION
    =======================================*/
    var handleSubmit = function () {
        setLoading(true);
        var body = new FormData();
        body.append("formValues", JSON.stringify(logicValues));
        body.append("logicType", JSON.stringify(logicType));
        console.log("formValues", logicValues);
        axios.post(ProjectConfig.api_url + "/v2/logic/addedit", body, {
            headers: {
                "site_id": localStorage.getItem('site-id-selected'),
            },
        })
            .then(function (response) {
            console.log("res", response.data);
            if (response.data.status === "success") {
                enqueueSnackbar('Success', { variant: 'success' });
                setOpenEditor(false);
                loadList();
            }
            else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
            .catch(function (err) {
            console.log(err.response.data);
        }).
            finally(function () {
            setLoading(false);
        });
    };
    var getsubTitle = function () {
        if (Object.keys(logicType).length > 0) {
            if (logicType.logic_type_id === 1) {
                return _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Shipping logic" }));
            }
            if (logicType.logic_type_id === 2) {
                return _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Purchase Order" }));
            }
            if (logicType.logic_type_id === 3) {
                return _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Bulk & Tiered Discounts" }));
            }
            if (logicType.logic_type_id === 4) {
                return _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Hide Variables" }));
            }
            if (logicType.logic_type_id === 5) {
                return _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Build A Bundle" }));
            }
            if (logicType.logic_type_id === 6) {
                return _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Custom" }));
            }
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ open: openEditor, onClose: function () { return setOpenEditor(false); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", PaperProps: { sx: { borderRadius: "20px", minWidth: "750px" } } }, { children: [_jsx("div", __assign({ className: "DialogContainer" }, { children: _jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsx("div", __assign({ className: "boxContainerHeader" }, { children: _jsxs("div", __assign({ className: "dialog_container_title" }, { children: [_jsxs("h2", __assign({ className: "dialog_title" }, { children: [_jsx("img", { src: ic_logic }), Object.keys(logicType).length === 0 ? "Select Logic Type" : logic_id ? "Edit Logic" : "Add Logic"] })), getsubTitle()] })) })), Object.keys(logicType).length === 0 ? (_jsx(LogicMenu, { setLogicType: setLogicType })) : (_jsxs("div", __assign({ className: "dialogFormContainer" }, { children: [Object.keys(logicType).length > 0 && logicType.logic_type_id === 1 &&
                                        _jsx(ShippingLogic, { setLogicType: setLogicType, logicValues: logicValues, setLogicvalues: setLogicValues }), Object.keys(logicType).length > 0 && logicType.logic_type_id === 2 &&
                                        _jsx(PurchaseOrder, { setLogicType: setLogicType, logicValues: logicValues, setLogicvalues: setLogicValues }), Object.keys(logicType).length > 0 && logicType.logic_type_id === 3 &&
                                        _jsx(BulkDiscount, { setLogicType: setLogicType, logicValues: logicValues, setLogicvalues: setLogicValues }), Object.keys(logicType).length > 0 && logicType.logic_type_id === 4 &&
                                        _jsx(HideVariables, { setLogicType: setLogicType, logicValues: logicValues, setLogicvalues: setLogicValues }), Object.keys(logicType).length > 0 && logicType.logic_type_id === 5 &&
                                        _jsx(BuildBundle, { setLogicType: setLogicType, logicValues: logicValues, setLogicvalues: setLogicValues }), Object.keys(logicType).length > 0 && logicType.logic_type_id === 6 &&
                                        _jsx(Custom, { setLogicType: setLogicType, logicValues: logicValues, setLogicvalues: setLogicValues })] })))] })) })), _jsxs("div", __assign({ className: "dialogButtons" }, { children: [Object.keys(logicType).length > 0 &&
                            _jsx(Button, __assign({ onClick: handleSubmit, variant: "contained", className: "button_1" }, { children: logic_id ? "Edit Logic" : "Add Logic" })), _jsx(Button, __assign({ onClick: function () {
                                setOpenEditor(false);
                                setLogicType({});
                                setLogicValues({});
                            }, variant: "contained", color: "secondary", className: "button_secondary" }, { children: "Cancel" }))] }))] })) }));
}
