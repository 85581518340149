var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconButton, TextField, FormControl, InputLabel, MenuItem, Select, } from "@mui/material";
import { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
var defaultValues = {
    logic_id: "",
    logic_name: "",
    conditions: {
        variables: [
            {
                variable: "",
                bag: "show",
                cart: "show",
                checkout: "show",
            }
        ],
    },
};
export default function HideVariables(props) {
    var setLogicType = props.setLogicType, logicValues = props.logicValues, setLogicvalues = props.setLogicvalues;
    useEffect(function () {
        console.log("logicValues----", logicValues);
        if (!logicValues.logic_id) {
            console.log("newww");
            setLogicvalues(defaultValues);
        }
    }, []);
    useEffect(function () {
        if (!logicValues.conditions || !Array.isArray(logicValues.conditions.variables)) {
            setLogicvalues(__assign(__assign({}, logicValues), { conditions: {
                    variables: [
                        {
                            variable: "",
                            bag: "show",
                            cart: "show",
                            checkout: "show",
                        }
                    ],
                } }));
        }
    }, [logicValues]);
    if (!logicValues.conditions || !Array.isArray(logicValues.conditions.variables)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "boxContainerHeader" }, { children: _jsx("div", __assign({ className: "boxContainerTitle" }, { children: _jsx("h2", __assign({ className: "h2_title" }, { children: "Hide variables" })) })) })), _jsx(TextField, { fullWidth: true, label: "Name", value: logicValues.logic_name, onChange: function (e) { return setLogicvalues(__assign(__assign({}, logicValues), { logic_name: e.target.value })); } }), _jsxs("div", __assign({ className: "input_group" }, { children: [_jsx("div", __assign({ className: "dialog_section_title" }, { children: _jsxs("div", { children: [_jsx("h2", __assign({ className: "h2_section_title" }, { children: "Variables" })), _jsx("span", __assign({ className: "subitle" }, { children: "Add more variables by clicking \u2018Add Variable\u201D" }))] }) })), logicValues.conditions.variables.map(function (variable, variableIndex) { return (_jsx("div", __assign({ className: "input_group" }, { children: _jsxs("div", __assign({ className: "input_row" }, { children: [_jsx(TextField, { fullWidth: true, label: "Variable", value: variable.variable, onChange: function (e) {
                                        var conditions = __assign({}, logicValues.conditions);
                                        conditions.variables[variableIndex].variable = e.target.value;
                                        setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                    } }), _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Bag" })), _jsxs(Select, __assign({ labelId: "demo-simple-select-label", id: "rule", value: variable.bag, label: "Bag", name: "bag", onChange: function (e) {
                                                var conditions = __assign({}, logicValues.conditions);
                                                conditions.variables[variableIndex].bag = e.target.value;
                                                setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                            } }, { children: [_jsx(MenuItem, __assign({ value: "hide" }, { children: "Hide" })), _jsx(MenuItem, __assign({ value: "show" }, { children: "Show" }))] }))] })), _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Cart" })), _jsxs(Select, __assign({ labelId: "demo-simple-select-label", id: "rule", value: variable.cart, label: "Cart", name: "cart", onChange: function (e) {
                                                var conditions = __assign({}, logicValues.conditions);
                                                conditions.variables[variableIndex].cart = e.target.value;
                                                setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                            } }, { children: [_jsx(MenuItem, __assign({ value: "hide" }, { children: "Hide" })), _jsx(MenuItem, __assign({ value: "show" }, { children: "Show" }))] }))] })), _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Checkout" })), _jsxs(Select, __assign({ labelId: "demo-simple-select-label", id: "rule", value: variable.checkout, label: "Checkout", name: "checkout", onChange: function (e) {
                                                var conditions = __assign({}, logicValues.conditions);
                                                conditions.variables[variableIndex].checkout = e.target.value;
                                                setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                            } }, { children: [_jsx(MenuItem, __assign({ value: "hide" }, { children: "Hide" })), _jsx(MenuItem, __assign({ value: "show" }, { children: "Show" }))] }))] })), _jsx(IconButton, __assign({ "aria-label": "delete", disabled: logicValues.conditions.variables.length === 1, onClick: function () {
                                        var conditions = __assign({}, logicValues.conditions);
                                        conditions.variables.splice(variableIndex, 1);
                                        setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                                    } }, { children: _jsx(DeleteIcon, {}) }))] })) }), variableIndex)); }), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                            var conditions = __assign({}, logicValues.conditions);
                            conditions.variables.push({ variable: "", bag: "show", cart: "show", checkout: "show" });
                            setLogicvalues(__assign(__assign({}, logicValues), { conditions: conditions }));
                        } }, { children: "Add Variable" }))] }))] }));
}
