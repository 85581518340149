var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';
import LogicEditor from './LogicEditor';
import ListPagination from 'elements/ListPagination';
import 'assets/css/main.css';
export default function PlanList(props) {
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), list = _b[0], setList = _b[1];
    var _c = useState({
        total_pages: 1,
        total_items: 0,
    }), pagination = _c[0], setPagination = _c[1];
    var queryParams = new URLSearchParams(window.location.search);
    var currentPage = parseInt(localStorage.getItem('page') || "1");
    var _d = useState(currentPage), page = _d[0], setPage = _d[1];
    var category = queryParams.get('category');
    var _e = useState(0), refreshList = _e[0], setRefreshList = _e[1];
    var _f = useState(false), openEditor = _f[0], setOpenEditor = _f[1];
    var _g = useState(null), menuAnchor = _g[0], setMenuAnchor = _g[1];
    var _h = useState(null), activeItem = _h[0], setActiveItem = _h[1];
    var _j = useState(false), openAlert = _j[0], setOpenAlert = _j[1];
    useEffect(function () {
        setLoading(true);
        loadList();
    }, [page]);
    /*=========================================================
    LOAD LIST
    =========================================================*/
    var loadList = function () {
        setLoading(true);
        axios.get(ProjectConfig.api_url + "/v2/logic/list", {
            headers: {
                "site_id": localStorage.getItem('site-id-selected'),
            }
        })
            .then(function (response) {
            console.log("Plan List ", response.data);
            setList(response.data.list);
            setPagination(response.data.pagination);
            setLoading(false);
        })
            .catch(function (err) {
            console.log(err);
        })
            .finally(function () {
            setLoading(false);
        });
        localStorage.setItem('page', page.toString());
    };
    /*=========================================================
    DELETE ITEM
    =========================================================*/
    var handleDelete = function (logic_id) {
        setActiveItem(logic_id);
        setOpenAlert(true);
    };
    var deleteItem = function () {
        var body = { logic_id: activeItem };
        axios.post(ProjectConfig.api_url + "/v2/logic/deleteItem", body)
            .then(function (response) {
            console.log(response.data);
            if (response.data.status === "success") {
                enqueueSnackbar('Item deleted successfully', { variant: 'success' });
                loadList();
            }
        })
            .catch(function (err) {
            console.log(err.data);
        })
            .then(function () {
            setOpenAlert(false);
        });
    };
    /*=========================================================
    HANDLE MENU OPEN
    =========================================================*/
    var handleMenuOpen = function (event, item) {
        setMenuAnchor(event.currentTarget);
        setActiveItem(item);
    };
    /*=========================================================
    HANDLE MENU CLOSE
    =========================================================*/
    var handleMenuClose = function () {
        setMenuAnchor(null);
    };
    /*=========================================================
    HANDLE EDIT
    =========================================================*/
    var handleEdit = function () {
        setOpenEditor(true);
        handleMenuClose();
    };
    return (_jsxs("div", __assign({ id: 'UsersList', className: 'ModuleContainer' }, { children: [_jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsxs("div", __assign({ className: "boxContainerHeader" }, { children: [_jsx("div", __assign({ className: "boxContainerTitle" }, { children: _jsx("h1", { children: "Logic" }) })), _jsx("div", __assign({ className: "boxContainerActions" }, { children: _jsx(Button, __assign({ variant: "outlined", color: "info", onClick: function () {
                                        setActiveItem(null);
                                        setOpenEditor(true);
                                    }, className: "btnList", startIcon: _jsx(AddIcon, {}) }, { children: "New Logic Condition" })) }))] })), _jsx("div", __assign({ id: 'UsersListIn' }, { children: _jsxs("div", { children: [_jsx(TableContainer, __assign({ sx: { width: "100%" } }, { children: _jsxs(Table, __assign({ className: "table" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Description" }), _jsx(TableCell, { children: "Type" }), _jsx(TableCell, { children: "Created" }), _jsx(TableCell, {})] }) }), _jsx(TableBody, { children: list.map(function (item, i) { return (_jsxs(TableRow, __assign({ hover: true, role: "checkbox" }, { children: [_jsx(TableCell, { children: item.logic_name }), _jsx(TableCell, { children: item.logic_type }), _jsx(TableCell, { children: item.created_at }), _jsxs(TableCell, __assign({ align: "right" }, { children: [_jsx(IconButton, __assign({ "aria-label": "edit", color: "primary", onClick: function (event) { return handleMenuOpen(event, item); } }, { children: _jsx(MoreHorizIcon, {}) })), _jsxs(Menu, __assign({ anchorEl: menuAnchor, open: Boolean(menuAnchor && activeItem && activeItem.logic_id === item.logic_id), onClose: handleMenuClose }, { children: [_jsxs(MenuItem, __assign({ onClick: function () {
                                                                                setActiveItem(item);
                                                                                setOpenEditor(true);
                                                                                handleMenuClose();
                                                                            } }, { children: [_jsx(EditIcon, { fontSize: "small" }), "Edit"] })), _jsxs(MenuItem, __assign({ onClick: function () { return handleDelete(item.logic_id); } }, { children: [_jsx(DeleteOutlineOutlinedIcon, { fontSize: "small" }), "Remove"] }))] }))] }))] }), i)); }) })] })) })), _jsx(ListPagination, { pagination: pagination, page: page, onChange: function (event, value) {
                                        setPage(value);
                                    }, label: "Logic", style: { marginTop: "20px", alignSelf: "center" } })] }) }))] })), _jsx(AlertDialog, { open: openAlert, setOpen: setOpenAlert, subtitle: "Are you sure you want to delete this item?", send: deleteItem }), _jsx(LogicEditor, { setLoading: setLoading, openEditor: openEditor, setOpenEditor: setOpenEditor, logic_id: activeItem ? activeItem.logic_id : null, loadList: loadList })] })));
}
;
